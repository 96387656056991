import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Components
import ContentHeader from "../../components/ContentHeader";

export const query = graphql`
  query PhotosQuery($year: Int!) {
    years(year: { eq: $year }) {
      year
      color
      banner {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            height: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: { fit: COVER }
          )
        }
      }
      photos {
        id
        name
        publicURL
        relativeDirectory
        childImageSharp {
          gatsbyImageData(
            width: 200
            height: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: { fit: COVER }
          )
        }
      }
      photo_sections {
        name
        id
        date
      }
    }
  }
`;

export interface PhotosPageProps {
  data: {
    years: any;
  };
}

export default class PhotosPage extends React.Component<PhotosPageProps> {
  state: {
    currentImage: string | undefined;
    previousImage: string | undefined;
    nextImage: string | undefined;
    visible: boolean;
  } = {
    currentImage: undefined,
    previousImage: undefined,
    nextImage: undefined,
    visible: false,
  };

  setImage(currentImage: string | undefined) {
    // fetch the previous image in the array
    const index = this.props.data.years.photos.findIndex(
      (node: any) => node.publicURL === currentImage
    );
    const previousImage = this.props.data.years.photos[index - 1]?.publicURL;
    const nextImage = this.props.data.years.photos[index + 1]?.publicURL;
    if (!currentImage) {
      this.setState({
        visible: false,
      });
      document.body.classList.remove("lightbox-active");
      setTimeout(() => {
        this.setState({
          currentImage: undefined,
          previousImage: undefined,
          nextImage: undefined,
        });
      }, 400);
      return;
    }
    const visible = !!currentImage;
    this.setState({
      visible,
      previousImage,
      currentImage,
      nextImage,
    });
    if (visible) {
      document.body.classList.add("lightbox-active");
    } else {
      document.body.classList.remove("lightbox-active");
    }
  }

  componentDidMount(): void {
    // register right and left arrow keys
    document.addEventListener("keydown", (event) => {
      if (event.key === "ArrowRight") {
        this.setImage(this.state.nextImage);
      } else if (event.key === "ArrowLeft") {
        this.setImage(this.state.previousImage);
      } else if (event.key === "Escape") {
        this.setImage(undefined);
      }
    });
  }

  render() {
    const year = this.props.data.years;
    const banner = getImage(year.banner);
    return (
      <>
        <div
          className={`fixed top-0 left-0 w-full h-full sm:p-8 p-2 bg-black bg-opacity-80 box-border ${
            this.state.visible
              ? "opacity-100 backdrop-blur-md"
              : "opacity-0 pointer-events-none"
          } transition-all duration-700 z-500`}
        >
          <div
            className="absolute top-0 left-0 w-full h-full -z-10 cursor-pointer"
            onClick={() => this.setImage(undefined)}
          />
          <div className="flex flex-col h-full w-full">
            <div className="relative h-full w-full">
              <div className="absolute top-0 left-0 flex flex-row justify-center items-center h-full w-full">
                {this.state.currentImage && (
                  <img
                    src={this.state.currentImage}
                    className="relative max-w-full max-h-full rounded-md z-20"
                  />
                )}
                <div
                  className="absolute top-0 left-0 h-full w-full cursor-pointer"
                  onClick={() => this.setImage(undefined)}
                />
              </div>
            </div>
            <div
              className="w-full h-8 cursor-pointer"
              onClick={() => this.setImage(undefined)}
            />
            <div className="flex flex-row justify-between items-center gap-16 flex-none">
              <div className="flex flex-row items-center gap-8">
                <span
                  className="material-symbols-rounded text-4xl text-white fill-current cursor-pointer hover:scale-110 hover:opacity-100 active:scale-100 active:opacity-50 transition-all duration-300 select-none flex-none"
                  onClick={() => this.setImage(undefined)}
                >
                  close
                </span>
                <a
                  href={this.state.currentImage}
                  download
                  className="material-symbols-rounded text-4xl text-white fill-current cursor-pointer hover:scale-110 hover:opacity-100 active:scale-100 active:opacity-50 transition-all duration-300 select-none flex-none"
                >
                  download
                </a>
              </div>
              <div
                className="w-full h-full cursor-pointer"
                onClick={() => this.setImage(undefined)}
              />
              <div className="flex flex-row items-center gap-8">
                <span
                  className="material-symbols-rounded text-4xl text-white fill-current cursor-pointer hover:scale-110 hover:opacity-100 active:scale-100 active:opacity-50 transition-all duration-300 select-none flex-none"
                  onClick={() => this.setImage(this.state.previousImage)}
                >
                  arrow_back
                </span>
                <span
                  className="material-symbols-rounded text-4xl text-white fill-current cursor-pointer hover:scale-110 hover:opacity-100 active:scale-100 active:opacity-50 transition-all duration-300 select-none flex-none"
                  onClick={() => this.setImage(this.state.nextImage)}
                >
                  arrow_forward
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <ContentHeader
            title={`Foto's Justitia ${year.year}`}
            background={banner}
            color={year.color}
            backLink={`/${year.year}`}
            backLabel={`Justitia ${year.year}`}
            colored_banner={true}
          />
          <div className="md:p-12 p-4 flex flex-col gap-4">
            <>
              {this.props.data.years.photo_sections == null && (
                <div className="grid grid-cols-1 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-2 sm:gap-4 w-full group">
                  {year.photos.map((node: any) => {
                    const image = getImage(node);
                    return (
                      image && (
                        <div
                          key={node.id}
                          onClick={() => {
                            this.setImage(node.publicURL);
                          }}
                        >
                          <GatsbyImage
                            image={image}
                            alt="Photo"
                            objectFit="cover"
                            loading="lazy"
                            className="hover:scale-105 hover:shadow-xl active:opacity-80 active:shadow-none hover:z-40 rounded-sm hover:rounded-lg active:scale-95 transition-all cursor-pointer"
                            style={{
                              width: "100%",
                              height: "0",
                              paddingBottom: "100%",
                            }}
                          />
                        </div>
                      )
                    );
                  })}
                </div>
              )}
            </>
            <>
              {this.props.data.years.photo_sections != null && (
                <>
                  {year.photo_sections.map((section: any) => (
                    <div key={section.id}>
                      <div className="py-8">
                        <h2 className="text-3xl font-bold">{section.name}</h2>
                        <h3 className="font-semibold">{section.date}</h3>
                      </div>
                      <div className="grid grid-cols-1 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-2 sm:gap-4 w-full group">
                        {year.photos
                          .filter((node: any) =>
                            node.relativeDirectory.includes(section.id)
                          )
                          .map((node: any) => {
                            const image = getImage(node);
                            return (
                              image && (
                                <div
                                  key={node.id}
                                  onClick={() => {
                                    this.setImage(node.publicURL);
                                  }}
                                >
                                  <GatsbyImage
                                    image={image}
                                    alt="Photo"
                                    objectFit="cover"
                                    loading="lazy"
                                    className="hover:scale-105 hover:shadow-xl active:opacity-80 active:shadow-none hover:z-40 rounded-sm hover:rounded-lg active:scale-95 transition-all cursor-pointer"
                                    style={{
                                      width: "100%",
                                      height: "0",
                                      paddingBottom: "100%",
                                    }}
                                  />
                                </div>
                              )
                            );
                          })}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          </div>
        </div>
      </>
    );
  }
}

export function Head({ data }: { data: any }) {
  const year = data.years;
  return <title>Foto's - Justitia {year.year}</title>;
}
